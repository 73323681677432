<template>
  <v-container
    :id="`${displayAsPlural.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ displayAsSingular }}</h3>
                </v-col>

                <v-col
                  sm="4"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    min-width="100"
                    :disabled="disableSubmit"
                    @click="copy_onClick"
                  >
                    COPY
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-container
            class="py-0 pb-5"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-left"
              >
                <copy-for-takeoff
                  ref="formCopyForTakeoff"
                  v-model="selected"
                  @change="disableSubmit = getDisableSubmit()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                offset-sm="8"
                sm="4"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  min-width="100"
                  :disabled="disableSubmit"
                  @click="copy_onClick"
                >
                  COPY
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </material-card>

        <confirmation-dialog
          ref="copyConfirmationDialog"
          @confirm="submit_onClick"
        >
          <template #title>
            <h2
              v-if="customers.selectedFrom.id === customers.selectedItem.id"
              class="primary--text"
            >
              Confirm Copy for Customer {{ customers.selectedItem.description }}
            </h2>
            <h2
              v-else
              class="primary--text"
            >
              Confirm Copy
            </h2>
          </template>

          <template #message>
            <div>
              <h3
                v-if="customers.selectedFrom.id !== customers.selectedItem.id"
                class="pb-2"
              >
                Copy from
                <span
                  class="accent--text"
                >
                  {{ customers.selectedFrom.description }}
                </span>
                to
                <span
                  class="secondary--text"
                >
                  {{ customers.selectedItem.description }}?
                </span>
              </h3>

              <v-divider v-if="customers.selectedFrom.id !== customers.selectedItem.id" />

              <div
                v-if="takeoffs.selectedSupportTables.length > 0"
                class="pt-2"
              >
                All customer rows from the following support tables...
                <ul>
                  <li
                    v-for="(supportTable, i) in takeoffs.selectedSupportTables"
                    :key="supportTable.id"
                  >
                    {{ supportTable.description }}
                  </li>
                </ul>
              </div>

              <div
                v-if="selected.length > 0 && takeoffs.selectedSupportTables.length > 0"
                class="pt-2"
                style="display: block;"
              >
                AND
              </div>

              <div
                v-if="selected.length > 0"
                class="pt-2"
              >
                The selected takeoff items from
                <span
                  class="accent--text"
                >
                  {{ plans.selectedFrom.description }}
                </span>
                to
                <span
                  class="secondary--text"
                >
                  {{ plans.selectedItem.description }}
                </span>
              </div>
            </div>
          </template>
        </confirmation-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import MaterialCard from '@/components/MaterialCard'
  import CopyForTakeoff from '@/components/maintenance/forms/CopyForTakeoff'
  import ConfirmationDialog from '@/components/maintenance/ConfirmationDialog'
  import { get } from 'vuex-pathify'

  export default {
    name: 'CopyView',

    components: {
      ConfirmationDialog,
      MaterialCard,
      CopyForTakeoff,
    },

    data () {
      return {
        appItem: null,
        displayAsSingular: 'Copy',
        displayAsPlural: 'Copies',
        disableSubmit: true,
        selected: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
      ]),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      takeoffs: get('takeoff/takeoffs'),
    },

    created () {
      this.appItem = this.getAppItem(this.$options.name)
      this.$store.dispatch('user/setPageName', this.$options.name)
    },

    methods: {
      getDisableSubmit () {
        let disabled = true

        if (this.$refs.formCopyForTakeoff) {
          disabled = this.$refs.formCopyForTakeoff.disableSubmit()
        }

        return disabled
      },

      copy_onClick () {
        this.$store.dispatch('takeoff/setSelectedTree', this.selected)

        this.$refs.copyConfirmationDialog.setDisplayDialog(true)
      },

      submit_onClick () {
        if (!this.disableSubmit) {
          this.$refs.formCopyForTakeoff.submit_onClick()
        }
      },
    },
  }
</script>
